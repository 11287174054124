@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
:root
{
  --purple: #914bf1;
}
html,body
{
  margin:0;
  padding:0;
  background:#fff;
  overflow-x: hidden;
  font-family: "Rubik", system-ui;
  
  position: relative;
  background-size: contain;
}

p{font-size: 1.2rem;line-height: 1.7rem;word-spacing: 0.2rem;}
.text-black{color:#343c55 !important}
#header{
  
  
 background-color:#fff;
  align-items: center;
  z-index: 2;
  
  position: fixed;
}

.dropdown-menu
{
  background: #701483 !important;
  box-shadow: 0px 2px 10px 5px rgb(57 57 57 / 19%) !important;
}
.logo
{
  height: 70px;
   
}
.nav-item a
{
  color:#343c55;
  font-family: "Lexend", sans-serif;font-optical-sizing: auto;
  font-size: 1.1rem;
}
.nav-item a:hover
{
  
  transition: 0.2s;
}
.dropdown-item:hover, .dropdown-item:focus
{
  background-color: #550965 !important;
}
.dropdown-item
{
  color:#fff !important;
}
#home
{
 
  height:100vh;
  
  display: flex;
  align-items: center;
}

.home-left h5,h2{font-family: "Pacifico", system-ui;}
.home-left h1, h4,h5,h2{color:#343c55 !important}
.home-left h1,h4{font-family: "Rubik", system-ui;}
.home-left h1{font-size: 5.3rem;}
.home-left h4{font-size: 1.4rem;font-weight: 300;}
.text-purple{color:#914bf1 !important}
.homeDotPatter
{
  position: absolute;
  top:-3em;
  right:-10em;
  z-index: 1;
  overflow:hidden !important;
}
.small-circle-pattern
{
  position: absolute;
  top:20rem;
  left:5em;
  z-index: 1;
  overflow:hidden !important;
  opacity: 0.6;
}
.home-read-more
{
  background-color:#914bf1 !important;
  margin-top:20px;
  color:#fff !important;
  padding: 10px 40px !important;
    font-size: 1.1rem !important;
    box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.2);
}




#about
{
  margin-top:30px;
  position: relative;
  margin-bottom:35px;
}
.about-row
{
  display: flex;
  align-items: center;
}
.about-heading
{
  font-family: "Rubik", system-ui;
  color:var(--purple) !important;
  font-size: 1.5rem;
}
.about-h2{font-size: 3.5rem;}

#services
{
  background-image: url('../public/service-bg.png'); /* Replace with your image URL */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  
  min-height:100vh;
  background-size: cover;
  position: relative;
  padding:100px 10px;
}

#services::before, #services::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px; /* Adjust the height as needed */
  background-color: white; /* Color for the curve */
  left: 0;
  z-index: 1;
  overflow: hidden;
}

#services::before {
  top: -50px; /* Adjust position as needed */
  border-radius: 0 0 100% 100%;
}

#services::after {
  bottom: -50px; /* Adjust position as needed */
  border-radius: 100% 100% 0 0;
}






.social-sides
{
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index:4;
  position: absolute;
  top:-5em;
  right:0;
}
.social-sides li
{
  padding:10px
}


.digital-card
{
  border:1px solid #1b1b1b;
  border-radius: 8px;
  padding:10px 10px;
  background: #b37ffb;
  z-index:3;
 
}
.digital-card h3{color:#1b1b1b;font-family: "Pacifico", system-ui;font-size:1.6rem;border-bottom: 2px solid #1b1b1b;}
.digital-card p{color:#1b1b1b;font-family: "Rubik", system-ui;font-size:1.2rem;font-weight: 400;}
.contact-btn {background-color:#914bf1 !important; border-color: #914bf1 !important;}

.footer{background-color: var(--purple);margin-top:30px;color:#fff;}

@media screen and (max-width:500px)
{
  #home{padding-top:30px; text-align: center !important;;}
  .home-left h1{font-size: 3.3rem;}
  .amoeba{display: none;}
  .about-row
{
  display: block !important;
  text-align: center;
 
}

  .homeDotPatter{z-index:-1;opacity:0.3}
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
#services{margin-top:60px;margin-bottom:60px;}
.list-unstyled{justify-content: center !important;}
.mt-sms-3{margin-top:1rem !important}
.small-circle-pattern{top:24rem !important}
.logo{height:55px !important}
}